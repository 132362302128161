<template>
  <v-card flat class="mb-2">
    <v-card-title class="pb-0 body-1 flex-nowrap align-start">
      <v-avatar size="26" color="grey lighten-1" class="white--text mr-2 body-2">{{ value.rank }}</v-avatar>
      {{ value.name }} <span v-if="value.required" class="red--text text-h5 ml-2">*</span>
    </v-card-title>
    <v-card-text class="pl-10">
      <survey-question-answer v-model="value" />
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: "SurveyQuestion",
    props: {
      value: Object
    },
    components: {
      SurveyQuestionAnswer: () => import("./SurveyQuestionAnswer")
    }
  };
</script>

<style></style>
